import React from 'react';
import * as classes from './CookieBanner.module.css';

const CookieBanner = ({acceptHandler}) => (
  <div className={classes.container}>
    <div className={classes.cookieBannerMain}>
    </div>
  </div>
);

export default CookieBanner;
